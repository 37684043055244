import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import type { AppProps } from 'next/app'
import { HydratingRoot } from 'react-hydrated'
import { PageInfo } from '../app/components/PageInfo'
import '../app/styles/globals.sass'
import { CategoryFilterContextProvider } from '../app/utils/CategoryFilterContext'
import { ProfileProvider } from '../app/utils/ProfileContext'
import { SearchContextProvider } from '../app/utils/SearchContext'
import { ThumbnailContextProvider } from '../app/utils/ThumbnailContext'

const queryClient = new QueryClient()

function MyApp({ Component, pageProps }: AppProps) {
	return (
		<HydratingRoot>
			<ProfileProvider>
				<SearchContextProvider>
					<CategoryFilterContextProvider>
						<ThumbnailContextProvider>
							<QueryClientProvider client={queryClient}>
								<PageInfo />
								<Component {...pageProps} />
							</QueryClientProvider>
						</ThumbnailContextProvider>
					</CategoryFilterContextProvider>
				</SearchContextProvider>
			</ProfileProvider>
		</HydratingRoot>
	)
}
export default MyApp

import React from 'react'
import type { PlantProfileResult } from '../data/fragments/PlantProfileFragment'

export function useCategoryFilterContextRoot() {
	const [selectedCats, setSelectedCats] = React.useState<string[]>([])
	const [openedCats, setOpenedCats] = React.useState<string[]>([])

	const _setCategoryFilter = (cats: React.SetStateAction<string[]>) => {
		setSelectedCats(cats)
	}

	const _setOpenCategories = (cats: React.SetStateAction<string[]>) => {
		setOpenedCats(cats)
	}

	const getCategoryFilter = () => {
		return selectedCats
	}

	const getOpenCategories = () => {
		return openedCats
	}

	const filterProfiles = (profiles: PlantProfileResult[]) => {
		console.log('jo')
		if (selectedCats.length > 0) {
			console.log('ano')
			return profiles.filter((item) => {
				let isIn = false
				item.activeRevision?.categories.forEach((item) => {
					if (selectedCats.includes(String(item.id))) {
						isIn = true
						return
					}
				})
				item.activeRevision?.subCategories.forEach((item) => {
					if (selectedCats.includes(String(item.id))) {
						isIn = true
						return
					}
				})
				return isIn
			})
		}
		return profiles
	}

	return {
		setCategoryFilter: setSelectedCats,
		getCategoryFilter,
		filterProfiles,
		setOpenedCats: setOpenedCats,
		getOpenCategories,
	}
}

export type CategoryFilterContext = ReturnType<typeof useCategoryFilterContextRoot>

export const CategoryFilterContext = React.createContext<null | CategoryFilterContext>(null)

export function CategoryFilterContextProvider(props: { children: React.ReactNode }) {
	const value = useCategoryFilterContextRoot()
	return (
		<CategoryFilterContext.Provider value={value}>{props.children}</CategoryFilterContext.Provider>
	)
}

export function useCategoryFilter() {
	const context = React.useContext(CategoryFilterContext)

	if (!context) {
		throw new Error('Missing CategoryFilterContext')
	}

	return context
}

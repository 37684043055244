import * as z from 'zod'

export const GrowingDay = z.object({
	ppfd: z.number().gt(0),
	dli: z.number().gt(0),
	lightOn: z.string().regex(/^(((([0-1][0-9])|(2[0-3])):?[0-5][0-9]:?[0-5][0-9]+$))/), //time HH:MM:SS
	lightOf: z.string().regex(/^(((([0-1][0-9])|(2[0-3])):?[0-5][0-9]:?[0-5][0-9]+$))/), //time HH:MM:SS
	startOfDayTime: z.string().regex(/^(((([0-1][0-9])|(2[0-3])):?[0-5][0-9]:?[0-5][0-9]+$))/), //time HH:MM:SS
	endOfDayTime: z.string().regex(/^(((([0-1][0-9])|(2[0-3])):?[0-5][0-9]:?[0-5][0-9]+$))/), //time HH:MM:SS
	dayPlantTemperature: z.number().gt(0),
	nightPlantTemperature: z.number().gt(0),
	daySoilTemperature: z.number().gt(0),
	nightSoilTemperature: z.number().gt(0),
	startWatering: z.number().gt(0),
	stopWatering: z.number().gt(0),
	airHumidity: z.number().gt(0),
})

export const DayName = z.enum([
	'monday',
	'tuesday',
	'wednesday',
	'thursday',
	'friday',
	'saturday',
	'sunday',
])

export const GrowingPhase = z.object({
	id: z.string(),
	name: z.string(),
	daysUntilNextPhase: z.string(),
	order: z.number(),
	phaseType: z.string(), //PhaseType id
	plan: z.object({
		monday: GrowingDay,
		tuesday: GrowingDay.nullable(),
		wednesday: GrowingDay.nullable(),
		thursday: GrowingDay.nullable(),
		friday: GrowingDay.nullable(),
		saturday: GrowingDay.nullable(),
		sunday: GrowingDay.nullable(),
	}),
})

class MockFile {}

const MockedFile = typeof File === 'undefined' ? (MockFile as unknown as typeof File) : File

export const PlantProfile = z.object({
	latinName: z.string(),
	private: z.boolean(),
	name: z.string(),
	categories: z.array(z.string()),
	preferedSubstrateFertilizationLevel: z.string(),
	subCategories: z.array(z.string()),
	author: z.string(),
	thumbnail: z.instanceof(MockedFile).optional(),
	images: z.array(z.instanceof(MockedFile)),
	preferedPh: z.number(),
	description: z.string(),
	phases: z.array(GrowingPhase),
})

export type PlantProfile = z.infer<typeof PlantProfile>
export type GrowingDay = z.infer<typeof GrowingDay>
export type GrowingPhase = z.infer<typeof GrowingPhase>
export type DayName = z.infer<typeof DayName>

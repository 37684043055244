import React, { ReactNode, useContext } from 'react'
import { DayName, GrowingDay, PlantProfile } from '../../types/PlantProfile'

function useProfileRoot(initial: PlantProfile) {
	const [profile, setProfile] = React.useState<PlantProfile>(initial)

	const valid = React.useMemo(() => {
		return PlantProfile.safeParse(profile)
	}, [profile])

	const isDayValid = React.useCallback(
		(activePhaseIndex: number, day: DayName) => {
			if (valid.success) {
				return true
			} else {
				if (GrowingDay.safeParse(profile.phases[activePhaseIndex].plan[day]).success) {
					return true
				}
				return false
			}
			// @TODO najít chybu pro den
		},
		[profile.phases, valid]
	)

	return { profile, setProfile, valid, isDayValid } as const
}

const ProfileContext = React.createContext<null | ReturnType<typeof useProfileRoot>>(null)

export function useProfile() {
	const profile = useContext(ProfileContext)
	if (!profile) {
		throw new Error('Missing profile')
	}
	return profile
}

type ProfileProviderProps = {
	children: ReactNode
}

export function ProfileProvider({ children }: ProfileProviderProps) {
	const profile = useProfileRoot({
		author: '',
		categories: [],
		private: false,
		latinName: '',
		name: '',
		thumbnail: undefined,
		images: [],
		preferedPh: 0,
		subCategories: [],
		description: '',
		phases: [],
		preferedSubstrateFertilizationLevel: '',
	})

	return <ProfileContext.Provider value={profile}>{children}</ProfileContext.Provider>
}

import React from 'react'

export function useThumbnailContextRoot() {
	const [thumbnail, setThumbnail] = React.useState<string | null>()

	return { setThumbnail, thumbnail }
}

export type ThumbnailContextType = ReturnType<typeof useThumbnailContextRoot>

export const ThumbnailContext = React.createContext<null | ThumbnailContextType>(null)

export function ThumbnailContextProvider(props: { children: React.ReactNode }) {
	const value = useThumbnailContextRoot()
	return <ThumbnailContext.Provider value={value}>{props.children}</ThumbnailContext.Provider>
}

export function useThumbnail() {
	const context = React.useContext(ThumbnailContext)

	if (!context) {
		throw new Error('Missing ThumbnailContext')
	}

	return context
}
